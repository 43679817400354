import React from "react"
import { Col, Container, Row } from "reactstrap"

const ResultSection: React.FC<any> = ({ title, children }) => {
  return (
    <section id="result">
      <Container style={{ maxWidth: "940px" }}>
        <Row className="justify-content-center">
          <Col className="results-wrapper" xs="12" md="">
            <h2>{title}</h2>
            {children}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ResultSection
