import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import AboutNizza from "../components/content/about-nizza"
import Hero from "../components/layout/hero"
import Layout from "../components/layout/layout"
import ResultSection from "../components/layout/result-section"
import SEO from "../components/seo/seo"

export enum classType {
  produkt = "Physische Produkte",
  dienst = "Dienstleistungen",
}

const NizzaKlasseTemplate: React.FC<any> = ({ pageContext, data, location }) => {
  const [searchResult, setSearchResult] = useState([])
  const [searchString, setSearchString] = useState("")

  const handleSearchResult = (newResults, newSearchString) => {
    setSearchResult(newResults)
    setSearchString(newSearchString)
  }

  const type = pageContext.data.type

  const seo = {
    title: `Welche Nizza-Klasse hat mein${
      type === classType.produkt ? " Produkt" : "e Dienstleistung?"
    } ⇒ Klasse ${type === classType.produkt ? "1-34" : "35-45"}`,
    keywords: pageContext.keywords,
    location: location
  }

  const image = data.fileName.childImageSharp.fluid

  const startingNumber = type === classType.produkt ? 1 : 35
  return (
    <Layout location={pageContext.slug}>
      <SEO {...seo} />

      <Hero fluidImage={image} title={pageContext.data.title}>
        <p>
          {type === classType.produkt
            ? `Die 34 Produktklassen (Nizza) beinhalten physische Alltagsgegenstände und Lebensmittel, aber auch virtuelle Produkte (z. B. Computersoftware).`
            : `Die 11 Dienstleistungsklassen (Nizza) beinhalten Services und Dienstleistungen, die für Konsumenten und Unternehmen erbracht werden.`}
        </p>
      </Hero>

      <ResultSection
        title={`Nizza-Klassen ${type === classType.produkt ? "1-34" : "35-45"}`}
      >
        <Row className="justify-content-between">
          <Col xs="12">
            <p style={{ paddingBottom: "28px" }}>
              Details zu den einzelnen Klassen erhältst du unter dem jeweiligen
              Link. Noch einfacher findest du die Nizza-Klasse{" "}
              {type === classType.produkt
                ? "deines Produkts"
                : "deiner Dienstleistung"}{" "}
              mit unserer <Link to="/">Volltext-Suche</Link> heraus!
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs="auto">
            <ol
              style={{
                counterReset: `my-awesome-counter ${startingNumber - 1}`,
              }}
            >
              {pageContext.data.classList.map(item => (
                <Link key={item.id} to={`/klasse/${item.id}`}>
                  <li>{item.title}</li>
                </Link>
              ))}
            </ol>
          </Col>
        </Row>
      </ResultSection>

      <AboutNizza></AboutNizza>
    </Layout>
  )
}
export default NizzaKlasseTemplate

export const query = graphql`
  query CoverImageType($image: String) {
    fileName: file(relativePath: { eq: $image }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 980) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
